import React from 'react';
import "../Styles/Product.css"
import Layout from '../Components/Layout/Layout';
import image from "../Assets/image1.jpg";
import { makeStyles } from '@mui/styles';
import { Button, Grid, Typography, } from '@mui/material';




const useStyles = makeStyles({
  imageContainer: {
    position: 'relative',
    width: '300px',
    height: '400px',
    overflow: 'hidden',
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: 'auto',
    objectFit: 'contain', // Maintain aspect ratio without cropping
  },
});


function Product() {
  const classes = useStyles();


  return (
    <>
      <Layout>
        <div style={{ background: 'white' }}>
          <Grid style={{ background: 'white', textAlign: 'center', display: 'flex', justifyContent: 'center', marginTop: '10%' }}>
            <Grid item style={{
              background: 'linear-gradient(90deg, #9747FF 0%, #4BA0FF 38.4%, #38B6FF 100%)',
              WebkitBackgroundClip: 'text',

              color: 'transparent',
              maxWidth: '90%',
              width: '100%',
              margin: '0 auto',
            }}>
              <Typography variant="h1" style={{
                fontSize: '60px',
                fontWeight: '700',
              }}>
                Products

              </Typography>
            </Grid>
          </Grid>

          <Grid style={{ textAlign: 'center', display: 'flex', paddingLeft: '10%', paddingRight: '10%', marginTop: '3%', }}>
            <Typography variant="body1">
              At HyperNxt, we redefine success in the digital realm. As a premier provider of comprehensive software solutions and digital marketing services, we empower businesses to thrive in the ever-evolving landscape of the online world.
            </Typography>
          </Grid>


          <Grid container spacing={5} justifyContent="center" style={{ marginTop: '10%' }}>
            {/* First component */}
            <Grid item xs={12} sm={12} md={5} justifyContent="center" style={{ marginLeft: '1%', marginRight: '1%' }}>
              <img src={image} style={{ width: '80%', marginLeft: '5%' }} />

            </Grid>



            {/* Second component */}
            <Grid item xs={12} sm={12} md={5} style={{ marginLeft: '1%', marginRight: '1%' }}>
              <div className="hypercms-container" style={{ display: 'flex' }}>

                <Typography style={{ fontSize: '24px', fontWeight: 'bold', color: 'black', marginLeft: '5%', marginRight: '2%', }}>
                  Hyper CMS
                </Typography>
                <Typography style={{ fontSize: '15px', color: 'black', marginTop: '1%', marginLeft: '1%', }}>(College Management System)</Typography>
              </div>
              <Typography variant="body1" style={{ textAlign: 'justify', marginTop: '5%', marginLeft: '5%', marginRight: '5%' }}>
                College Management System (CMS) is a specialized type of software designed to handle various administrative tasks within educational institutions, specifically colleges. This system typically includes features such as student enrolment, course management, faculty management, attendance tracking, examination management, grade management, financial management, and various other administrative functions relevant to colleges.
              </Typography>
              <Grid container justifyContent="center" style={{ marginTop: '5%' }}>
                <Button variant="contained" color="primary" style={{ width: '60%', alignContent: 'right', marginTop: '5%', borderRadius: "25px" }}>
                 Book a demo
                </Button>
              </Grid>
            </Grid>

          </Grid>


          {/* Grid Container 2 with text content aligned to the left */}
          <Grid container spacing={2} justifyContent="flex-start" alignItems="center" style={{ marginTop: '10%' }}>
            <Grid item xs={12} sm={6} order={{ xs: 1 }}>
              {/* Empty space for small screens */}
              <div style={{ display: 'none', visibility: 'hidden' }}></div>
              {/* Text content aligned to the left */}
              <div className="hypercms-container" style={{ display: 'flex' }}>
                <Typography style={{ fontSize: '24px', fontWeight: 'bold', color: 'black', marginLeft: '5%' }}>
                  Hyper CRS
                </Typography>
                <Typography style={{ fontSize: '15px', color: 'black', marginTop: '1%', marginLeft: '2%' }}>(College Relationship System)</Typography>
              </div>
              <Typography variant="body1" style={{ textAlign: 'justify', marginTop: '5%', marginLeft: '5%', marginRight: '5%' }}>
                CRM stands for Customer Relationship Management. It refers to the practices, strategies, and technologies that companies use to manage and analyze interactions with current and potential customers. The goal of CRM is to improve business relationships, streamline processes, and increase sales.
                CRM software typically helps businesses manage customer data, track interactions, automate tasks, analyze sales and customer data, and facilitate communication and collaboration among teams. By centralizing customer information and providing insights into customer behavior and preferences, CRM systems enable companies to better understand their customers, personalize interactions, and ultimately drive sales and customer satisfaction.
              </Typography>
              <Grid container justifyContent="center" style={{ marginTop: '5%' }}>
                <Button variant="contained" color="primary" style={{ width: '60%', alignContent: 'right', marginTop: '5%', borderRadius: "25px", }}>
                 Book a demo
                </Button>
              </Grid>
            </Grid>


          </Grid>



          {/* Grid Container 1 with text content aligned to the right */}
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center" style={{ marginTop: '10%' }}>
            <Grid item xs={12} sm={6} order={{ xs: 2 }}>
              {/* Empty space for small screens */}
              <div style={{ display: 'none', visibility: 'hidden' }}></div>
              {/* Text content aligned to the right */}

              <div className="hypercms-container" style={{ display: 'flex' }}>
                <Typography style={{ fontSize: '24px', fontWeight: 'bold', color: 'black', marginLeft: '5%' }}>
                  Hyper LMS
                </Typography>
                <Typography style={{ fontSize: '15px', color: 'black', marginTop: '1%', marginLeft: '2%' }}>(Learning Management System)</Typography>
              </div>
              <Typography variant="body1" style={{ textAlign: 'justify', marginTop: '5%', marginRight: '5%', marginLeft: '5%' }}>
                LMS stands for Learning Management System. It's a software application or platform used to administer, deliver, track, and manage educational courses and training programs. LMSs are widely used in educational institutions, corporations, and other organizations to provide online learning and training opportunities.
              </Typography>
              <Grid container justifyContent="center" style={{ marginTop: '5%' }}>
                <Button variant="contained" color="primary" style={{ width: '60%', alignContent: 'right', marginTop: '5%', borderRadius: "25px" }}>
                 Book a demo
                </Button>
              </Grid>

            </Grid>
          </Grid>

          {/* Grid Container 2 with text content aligned to the left */}
          <Grid container spacing={2} justifyContent="flex-start" alignItems="center" style={{ marginTop: '10%' }}>
            <Grid item xs={12} sm={6} order={{ xs: 1 }}>
              {/* Empty space for small screens */}
              <div style={{ display: 'none', visibility: 'hidden' }}></div>
              {/* Text content aligned to the left */}

              <div className="hypercms-container" style={{ display: 'flex' }}>
                <Typography style={{ fontSize: '24px', fontWeight: 'bold', color: 'black', marginLeft: '5%' }}>
                  Hyper PMS
                </Typography>
                <Typography style={{ fontSize: '15px', color: 'black', marginTop: '1%', marginLeft: '2%' }}>(Property Management System)</Typography>
              </div>
              <Typography variant="body1" style={{ textAlign: 'justify', marginTop: '5%', marginLeft: '5%', marginRight: '5%' }}>
                It's a software application used by hotels, resorts, and other hospitality businesses to manage their day-to-day operations, including reservations, guest check-ins and check-outs, room assignments, billing and invoicing, housekeeping schedules, and more. A property management system streamlines operations and enhances guest experiences by centralizing and automating various tasks related to property management.
              </Typography>
              <Grid container justifyContent="center" style={{ marginTop: '5%' }}>
                <Button variant="contained" color="primary" style={{ width: '60%', alignContent: 'right', marginTop: '5%', borderRadius: "25px" }}>
                 Book a demo
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* Grid Container 3 with text content aligned to the right */}
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center" style={{ marginTop: '10%' }}>
            <Grid item xs={12} sm={6} order={{ xs: 2 }}>
              {/* Empty space for small screens */}
              <div style={{ display: 'none', visibility: 'hidden' }}></div>
              {/* Text content aligned to the right */}
              <div className="hypercms-container" style={{ display: 'flex' }}>
                <Typography style={{ fontSize: '24px', fontWeight: 'bold', color: 'black', marginLeft: '5%' }}>
                  Hyper Payroll
                </Typography>
              </div>

              <Typography variant="body1" style={{ textAlign: 'justify', marginTop: '5%', marginRight: '5%', marginLeft: '5%' }}>
                Payroll refers to the process of calculating and disbursing payment to employees for their work within an organization. It encompasses various tasks such as calculating wages, withholding taxes and deductions, processing employee benefits, and issuing paychecks or direct deposits.

                Payroll can be processed manually using spreadsheets or automated through dedicated payroll software or integrated HR management systems. Automating payroll processes can help organizations save time, reduce errors, and ensure compliance with tax and labor regulations.
              </Typography>
              <Grid container justifyContent="center" style={{ marginTop: '5%' }}>
                <Button variant="contained" color="primary" style={{ width: '60%', alignContent: 'right', marginTop: '5%', borderRadius: "25px" }}>
                 Book a demo
                </Button>
              </Grid>


            </Grid>
          </Grid>

          {/* Grid Container 4 with text content aligned to the left */}
          <Grid container spacing={2} justifyContent="flex-start" alignItems="center" style={{ marginTop: '10%' }}>
            <Grid item xs={12} sm={6} order={{ xs: 1 }}>
              {/* Empty space for small screens */}
              <div style={{ display: 'none', visibility: 'hidden' }}></div>
              {/* Text content aligned to the left */}

              <div className="hypercms-container" style={{ display: 'flex' }}>
                <Typography style={{ fontSize: '24px', fontWeight: 'bold', color: 'black', marginLeft: '5%' }}>
                  Hyper E-Commerce
                </Typography>
              </div>
              <Typography variant="body1" style={{ textAlign: 'justify', marginTop: '5%', marginLeft: '5%', marginRight: '5%' }}>
                E-commerce, short for electronic commerce, refers to the buying and selling of goods or services over the internet. It encompasses a wide range of transactions, including online retail, electronic payments, digital marketing, and supply chain management. E-commerce has transformed the way businesses operate and consumers shop, offering convenience, accessibility, and a global reach.
              </Typography>
              <Grid container justifyContent="center" style={{ marginTop: '5%' }}>
                <Button variant="contained" color="primary" style={{ width: '60%', alignContent: 'right', marginTop: '5%', borderRadius: "25px" }}>
                 Book a demo
                </Button>
              </Grid>
            </Grid>
          </Grid>



          {/* Grid Container 5 with text content aligned to the right */}
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center" style={{ marginTop: '10%' }}>
            <Grid item xs={12} sm={6} order={{ xs: 2 }}>
              {/* Empty space for small screens */}
              <div style={{ display: 'none', visibility: 'hidden' }}></div>
              {/* Text content aligned to the right */}
              <div className="hypercms-container" style={{ display: 'flex' }}>
                <Typography style={{ fontSize: '24px', fontWeight: 'bold', color: 'black', marginLeft: '5%' }}>
                  Hyper Bills
                </Typography>
              </div>

              <Typography variant="body1" style={{ textAlign: 'justify', marginTop: '5%', marginRight: '5%', marginLeft: '5%' }}>
                Hyper Bills refers to the process Billing and inventory management software are essential tools for businesses to efficiently handle their sales, invoicing, and stock levels. Hyper Bills can help businesses streamline their operations, improve inventory accuracy, and enhance customer satisfaction through efficient billing processes and optimized inventory management.
              </Typography>
              <Grid container justifyContent="center" style={{ marginTop: '5%' }}>
                <Button variant="contained" color="primary" style={{ width: '60%', alignContent: 'right', marginTop: '5%', borderRadius: "25px" }}>
                 Book a demo
                </Button>
              </Grid>


            </Grid>
          </Grid>



          {/* Grid Container 6 with text content aligned to the left */}
          <Grid container spacing={2} justifyContent="flex-start" alignItems="center" style={{ marginTop: '10%' }}>
            <Grid item xs={12} sm={6} order={{ xs: 1 }}>
              {/* Empty space for small screens */}
              <div style={{ display: 'none', visibility: 'hidden' }}></div>
              {/* Text content aligned to the left */}

              <div className="hypercms-container" style={{ display: 'flex' }}>
                <Typography style={{ fontSize: '24px', fontWeight: 'bold', color: 'black', marginLeft: '5%' }}>
                  Hyper Projects
                </Typography>
              </div>
              <Typography variant="body1" style={{ textAlign: 'justify', marginTop: '5%', marginLeft: '5%', marginRight: '5%' }}>
                Serving as an intermediary, the middleman capitalizes on a multitude of advantages in selling software developed by another company. By branding the software under their own name, they establish credibility and recognition, while maintaining control over pricing and marketing efforts to attract clients effectively. This approach not only diversifies revenue streams but also fosters direct client interaction, leading to stronger relationships and expanded business opportunities. With the flexibility to set prices competitively and leverage industry expertise to sell and support the software, the middleman gains a competitive edge and strengthens customer relationships. Additionally, offering value-added services alongside the software enhances revenue opportunities and provides added value to clients, further solidifying their position in the market. This model not only mitigates the risks associated with software development but also enables scalable business expansion, tapping into new markets and industries with ease, ultimately leading to increased profitability and business success.
              </Typography>
              <Grid container justifyContent="center" style={{ marginTop: '5%' }}>
                <Button variant="contained" color="primary" style={{ width: '60%', alignContent: 'right', marginTop: '5%', borderRadius: "25px", marginBottom: '5%' }}>
                 Book a demo
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Layout >
    </>
  );
}

export { Product as default };