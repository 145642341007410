import React from 'react'
import { Container, Box, Typography, Button } from '@mui/material';
import hub from '../../Assets/hyperhub.png'
import { FaArrowRightLong } from "react-icons/fa6";
import EastIcon from '@mui/icons-material/East';

const Joinhub = () => {
    const buttonStyles = {
        width: { lg: '200px', md: '250px', sm: '200px', xs: '200px' },
        height: { lg: '50px', md: '45px', sm: '40px', xs: '30px' },
        color: '#fff',
        background: '#38B6FF',
        borderRadius: '30px',
        fontSize: { lg: '18px', md: '17px', sm: '16px', xs: '10px' },
        margin: { lg: '30px 0px', md: '30px 0px', sm: '30px 0px', xs: '15px 0px' },
        padding: '0px',
        fontWeight: '500',
        fontFamily: 'poppins',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)',
          background:'#5fca4d'
        },
      };
  return (
    <Container maxWidth="lg" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
      <Box
        sx={{
            width: '85vw',
        //   maxWidth: '1372px',
        height: { lg: '70vh', md: '50vh', sm: '30vh', xs: '35vh' },
        //   maxHeight: '523px',
          flexShrink: 0,
          borderRadius: '50px',
          border: '1px solid rgba(11, 11, 11, 0.10)',
          background: '#FFF',
          boxShadow: '0px 10px 15px 0px rgba(0, 0, 0, 0.25)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
          boxSizing: 'border-box',
        }}
      >
                <Typography variant='h4' sx={{fontWeight:'700',fontFamily:'poppins',fontSize:{lg:'50px',md:'40px',sm:'27px',xs:'22px'}}} component={'div'}>About   <Typography variant='h4'  component={'span'} sx={{fontWeight:'700',fontFamily:'poppins',color:'#0abde3',fontSize:{lg:'50px',md:'40px',sm:'27px',xs:'22px'}}}>HYPERHUB</Typography></Typography>

                <Typography sx={{margin:'10px 0px',fontSize:{lg:'20px',md:'15px',sm:'10px',xs:'8px',color:'#0B0B0B66',textAlign:'center'},         margin: { lg: '30px 0px', md: "30px 0px", sm: '30px 0px', xs: '15px 0px' },
}}>
                At HyperNxt, we redefine success in the digital realm. As a premier provider of comprehensive software solutions and digital marketing services, we empower businesses to thrive in the ever-evolving landscape of the online world.
                </Typography>

        <Box component="img" src={hub} alt="hyperhub" sx={{  width: { lg: '17%', md: '20%', sm: '20%', xs: '40%' },marginBottom: '20px' }} />
        {/* <Button
          sx={buttonStyles}
          variant="contained"
          endIcon={<EastIcon style={{ color: 'white' }} />}
        >
          <a target="_blank" href="https://hyperhub.in" style={{ color: 'inherit', textDecoration: 'none' }}>
            Visit Now
          </a>
        </Button> */}

      </Box>
    </Container>
  )
}

export default Joinhub
